import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { escapeEmailAddress } from "../components/mailto-link"
import PageHeading from "../components/page-heading"

const ImprintPage = () => {
  const data = useStaticQuery(graphql`
    query ImprintQuery {
      site {
        siteMetadata {
          contact {
            email
            phone
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Impressum" />
      <PageHeading heading="Impressum" />
      <section>
        <Container>
          <Row>
            <Col className="col-12 mb-4">
              <h2>Angaben gemäß § 5 TMG</h2>
              <p>
                viind GmbH
                <br />
                Dettelbachergasse
                <br />
                97070 Würzburg
              </p>

              <p>
                Handelsregister: HRB 15651
                <br />
                Registergericht: Würzburg
              </p>

              <p>
                <strong>Vertreten durch:</strong>
                <br />
                Matthias Clad
                <br />
                Michael Gabler
              </p>

              <h2>Kontakt</h2>
              <p>
                Telefon: {data.site.siteMetadata.contact.phone}
                <br />
                E-Mail:{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: escapeEmailAddress(["info", "viind.com"]),
                  }}
                />
              </p>

              <h2>Umsatzsteuer-ID</h2>
              <p>
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a
                Umsatzsteuergesetz:
                <br />
                DE343760772
              </p>

              <h2>EU-Streitschlichtung</h2>
              <p>
                Die Europäische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:{" "}
                <a
                  href="https://ec.europa.eu/consumers/odr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr/
                </a>
                .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>

              <h2>
                Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
              </h2>
              <p>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </p>

              <p>
                Diese Webseite enthält Icons von{" "}
                <a
                  href="https://www.flaticon.com/authors/freepik"
                  target="_blank"
                >
                  Freepik
                </a>{" "}
                von{" "}
                <a href="https://www.flaticon.com/" target="_blank">
                  www.flaticon.com
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ImprintPage
